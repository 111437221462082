/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from '@hospy/hospy-ui';
import {
	APPS,
	getPropertyIntegrations,
	PARTNER,
	PropertyIntegrations,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import {
	getPropertyIntegrationsService,
	getPropertyService,
	validateCredentialsService
} from '@hospy/util-api';
import { message, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import usePropertyIntegration from '../../hooks/use-property-integration';
import AuthPartner from '../../pages/connect-partner/components/auth-partner';
import useConnectPartnerAuthOauth from '../../pages/connect-partner/hooks/use-connect-partner-auth-oauth';

interface Props {
	open: boolean;
	onClose: () => void;
}

const RestoreCloudbedsConnection = ({ open, onClose }: Props) => {
	const dispatch = useAppDispatch();
	const {
		remove: removePropertyIntegration,
		patch: patchPropertyIntegration
	} = usePropertyIntegration();

	const { appData } = useAppSelector(({ common }) => common);
	const { propertyId, propertyIntegrationsState } = useAppSelector(
		({ user }) => user
	);

	const [currentIntegration, setCurrentIntegration] =
		useState<PropertyIntegrations>();
	const [error, setError] = useState<string | React.ReactElement>();
	const [authStarted, setAuthStarted] = useState<boolean>(false);

	const partner = useMemo(() => {
		if (!appData) return undefined;
		const { partnerIntegrations } = appData;
		if (partnerIntegrations) {
			return (
				partnerIntegrations.find(
					(partner) => partner._id === PARTNER.CLOUDBEDS
				) || undefined
			);
		}
		return undefined;
	}, [appData?._id]);

	const { processOAuth } = useConnectPartnerAuthOauth({
		partnerIntegration: partner
	});

	useEffect(() => {
		const hasCloudbeds = propertyIntegrationsState.data?.find(
			(integration) =>
				integration.partnerIntegrationId === PARTNER.CLOUDBEDS &&
				integration.appId === APPS.SIIGO_CONNECT
		);
		if (hasCloudbeds && hasCloudbeds.accessTokenRevoked) {
			setCurrentIntegration(hasCloudbeds);
		}
	}, [propertyIntegrationsState.loading]);

	const validateCredentials = async (accessToken: string) => {
		if (partner?._id) {
			const { data } = await validateCredentialsService({
				partnerIntegrationId: partner?._id,
				mode: partner.authMode,
				accessToken
			});
			return data?.data;
		}
	};

	const onSubmit = async ({ clientId, clientSecret }: any) => {
		setError('');

		try {
			if (
				!appData ||
				!partner ||
				!propertyId ||
				!currentIntegration ||
				!currentIntegration._id
			) {
				// setLoading('failed');
				setError(
					React.createElement(
						'div',
						null,
						`Ya existe una integración en la aplicación actual`
					)
				);
				return;
			}
			let returnUrl = '';
			if (appData._id === APPS.SIIGO_CONNECT) {
				returnUrl = process.env['NX_APP_SIIGO_CONNECT'] || '';
			} else if (appData._id === APPS.SIRE_TRA) {
				returnUrl = process.env['NX_APP_COLOMBIA_SIRE_TRA'] || '';
			} else if (appData._id === APPS.EFACTURA_SAT) {
				returnUrl = process.env['NX_APP_EFACTURA_SAT'] || '';
			}
			const oAuthAccess = await processOAuth(
				clientId,
				clientSecret,
				returnUrl,
				returnUrl + 'partner/oauth'
			);

			const authCredentials = { ...oAuthAccess };

			const { propertyID } = await validateCredentials(
				oAuthAccess.accessToken
			);

			const { data: piData } = await getPropertyIntegrationsService({
				filter: `{"partnerIntegrationId":ObjectId("${partner._id}"),"propertyPartner.id":"${propertyID}","appId":ObjectId("${appData?._id}"), "propertyId": {$ne: ObjectId("${propertyId}")}}`
			});

			console.log({ piData });

			if (piData && piData.length > 0) {
				const { data: propertyData } = await getPropertyService(
					piData[0]?.propertyId
				);

				// setLoading('failed');
				setError(
					React.createElement(
						'div',
						null,
						`El negocio de Cloudbeds que intentas conectar ya se encuentra asociada al negocio `,
						React.createElement('b', null, propertyData[0]?.name),
						` en Hospy, Por favor usa otro negocio.`
					)
				);
				return;
			}

			const propertyIntegrationToUpdate: PropertyIntegrations = {
				...currentIntegration,
				...authCredentials,
				accessTokenRevoked: false
			};

			console.log(
				`${propertyIntegrationToUpdate.refreshToken} - ${authCredentials.refreshToken}`
			);

			console.log('creating', {
				propertyIntegrations: propertyIntegrationToUpdate,
				authCredentials
			});

			const updatePropertyIntegration = await patchPropertyIntegration(
				currentIntegration._id,
				propertyIntegrationToUpdate
			);

			if (!updatePropertyIntegration) {
				// setLoading('failed');
				setError(
					React.createElement(
						'div',
						null,
						`No se pudo completar la conexión, por favor intente nuevamente`
					)
				);
				return;
			}

			console.log({ updatePropertyIntegration });
			await dispatch(getPropertyIntegrations(propertyId));
			// setLoading('succeeded');
			setAuthStarted(false);
			message.success('Conexión restablecida con éxito');
			onClose();

			return;
		} catch (error: any) {
			console.error({ error });
			// setLoading('failed');
			if (
				error ===
				'Warning: Popup was closed before completing authentication.'
			) {
				setError(
					'No se pudo completar la autenticación, por favor intente nuevamente'
				);
				return;
			}
			if (propertyId) {
				const { data } = await getPropertyIntegrationsService({
					propertyId
				});
				if (data) {
					const pi = data.find(
						({ partnerIntegrationId, appId }: any) =>
							partnerIntegrationId === partner?._id &&
							appId === APPS.EFACTURA_SAT
					);

					if (pi?._id) removePropertyIntegration(pi._id);

					setError(
						React.createElement(
							'div',
							null,
							'No se pudo completar el proceso de validación con CLOUDBEDS, intente nuevamente',
							React.createElement('div', null, error)
						)
					);
				}
			}
		}
	};

	if (!partner) return <div />;

	return (
		<Modal onClose={() => null} open={open} closable={false}>
			<ModalWrapper>
				<Title>Restablecer conexión con Cloudbeds</Title>
				<Text>
					Se requiere conectar de nuevo tu cuenta de Cloudbeds
				</Text>
				{!authStarted && (
					<Button
						onClick={() => setAuthStarted(true)}
						size="large"
						type="primary"
					>
						Restablecer conexión
					</Button>
				)}
				{authStarted && (
					<AuthPartner
						onBack={() => {
							console.log('onBack');
						}}
						backText="Volver atrás"
						auth="OAUTH"
						onSubmit={onSubmit}
						loading="idle"
						error={error}
						hideTitle
						partnerIntegration={partner}
						appId={appData?._id}
						hideBack
					/>
				)}
			</ModalWrapper>
		</Modal>
	);
};

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	padding-inline: 80px;

	button {
		height: 60px;
	}
`;

const Title = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.title.size.large}px;
`;

const Text = styled(Typography.Text)`
	font-size: ${({ theme }) => theme.text.size.large}px;
`;

export default RestoreCloudbedsConnection;
