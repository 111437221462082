/* eslint-disable @typescript-eslint/no-explicit-any */
import { PartnerIntegration } from '@hospy/store';
import { getPartnerIntegrationOauthService } from '@hospy/util-api';
import moment from 'moment';
import { useRef } from 'react';

const OAUTH_STATE_KEY = 'pms-oauth2-state-key';
const POPUP_WIDTH = 500;
const POPUP_HEIGHT = 700;
const OAUTH_RESPONSE = 'react-use-oauth2-response';

const errorDictionary: any = {
	'The client secret supplied for a confidential client is invalid.':
		'El client secret suministrado no es válido',
	invalid_scope:
		'No tienes los permisos suficientes para completar tu conexión',
	'The authorization code is invalid or has expired.':
		'El código de autorización no es válido o ha caducado.'
};

export interface OAuthAccessData {
	apiToken: string;
	accessToken: string;
	refreshToken: string;
	accessTokenExpirationDate: Date;
}

interface IUseConnectPartnerAuthOauth {
	processOAuth: (
		clientId: string,
		clientSecret: string,
		baseUrl?: string,
		authUrlCallback?: string,
		partner?: string,
		response_type?: string | boolean
	) => Promise<OAuthAccessData>;
}

let processed = false;

const useConnectPartnerAuthOauth = ({
	partnerIntegration
}: {
	partnerIntegration: PartnerIntegration | undefined;
}): IUseConnectPartnerAuthOauth => {
	const intervalRef = useRef<any>();
	const popupRef: any = useRef();

	const processOAuth = (
		clientId: string,
		clientSecret: string,
		baseUrl?: string,
		authUrlCallback?: string,
		partner?: string,
		response_type?: string | boolean
	): Promise<OAuthAccessData> =>
		new Promise((resolve, reject) => {
			//TODO refactorizar para eliminar este if
			if (!baseUrl) baseUrl = process.env['NX_APP_COLOMBIA_SIRE_TRA'];

			const state = __generateState();
			sessionStorage.setItem(OAUTH_STATE_KEY, state);
			const url = __prepareAuthorizeUrl(
				partnerIntegration?.authUrl || '',
				clientId,
				`${baseUrl || ''}partner${partner ? '/' + partner : ''}/oauth`,
				typeof response_type !== 'undefined' && response_type === false
					? response_type
					: 'code',
				state,
				partnerIntegration?.scope || ''
			);

			popupRef.current = __openPopup(url);

			const _handleMessageListener = async (message: any) => {
				try {
					const type = message?.data?.type;

					if (type === OAUTH_RESPONSE) {
						const errorMaybe = message?.data?.error;

						if (errorMaybe) {
							throw new Error(errorMaybe);
						}

						const code =
							message && message.data && message.data.code;

						if (!processed) {
							processed = true;
							const { data }: any =
								await getPartnerIntegrationOauthService({
									partnerId: partnerIntegration?._id || '',
									code,
									clientId: clientId || '',
									clientSecret: clientSecret || '',
									authUrlCallback: authUrlCallback || ''
								});

							if (data.error_description) {
								if (errorDictionary[data.error_description])
									reject(
										errorDictionary[data.error_description]
									);
								else reject(data.error_description);
								return;
							}

							const { access_token, refresh_token, expires_in } =
								data;
							const d = {
								accessToken: access_token,
								refreshToken: refresh_token,
								accessTokenExpirationDate: moment()
									.add(expires_in, 'seconds')
									.toDate()
							} as OAuthAccessData;
							processed = false;
							__cleanup(
								intervalRef,
								popupRef,
								_handleMessageListener
							);
							resolve(d);
						}
					}
				} catch (error: any) {
					console.error({ error });
					reject(error.toString());
					__cleanup(intervalRef, popupRef, _handleMessageListener);
				}
			};

			window.addEventListener('message', _handleMessageListener);

			intervalRef.current = setInterval(() => {
				const popupClosed =
					!popupRef.current ||
					!popupRef.current.window ||
					popupRef.current.window.closed;
				if (popupClosed) {
					console.warn(
						'Warning: Popup was closed before completing authentication.'
					);
					__cleanup(intervalRef, popupRef, _handleMessageListener);
					reject(
						'Warning: Popup was closed before completing authentication.'
					);
				}
			}, 250);
		});

	return {
		processOAuth
	};
};
export default useConnectPartnerAuthOauth;

const __generateState = () => {
	const validChars =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let array: any = new Uint8Array(40);
	window.crypto.getRandomValues(array);
	array = array.map((x: number) =>
		validChars.codePointAt(x % validChars.length)
	);
	const randomState = String.fromCharCode.apply(null, array);
	return randomState;
};

const __prepareAuthorizeUrl = (
	url: string,
	clientId: string,
	redirect?: string,
	responseType?: string | false,
	state?: string,
	scope?: string
) => {
	const params = new URLSearchParams({ client_id: clientId });
	if (redirect) params.append('redirect_uri', redirect);
	if (responseType) params.append('response_type', responseType || 'code');
	if (state) params.append('state', state);
	if (scope) params.append('scope', scope);
	return `${url}?${params.toString()}`;
};

const __openPopup = (url: string): any => {
	const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
	const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
	// const right = 10;
	return window.open(
		url,
		'OAuth2 Popup',
		`height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
	);
};

const __cleanup = (
	intervalRef: any,
	popupRef: any,
	handleMessageListener: any
) => {
	clearInterval(intervalRef.current);
	popupRef.current?.close();
	__removeState();
	window.removeEventListener('message', handleMessageListener);
};

const __removeState = () => {
	sessionStorage.removeItem(OAUTH_STATE_KEY);
};
