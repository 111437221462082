/* eslint-disable @typescript-eslint/no-explicit-any */
import { APPS, PARTNER, useAppDispatch, useAppSelector } from '@hospy/store';
import { Select } from 'antd';
import { getAllListings } from 'libs/store/src/lib/tra/tra.thunks';
import { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import InvoiceFilterContext, {
	InvoiceFilterContextType
} from '../../../../context';

const FilterByRoom = () => {
	const dispatch = useAppDispatch();

	const { partnerIntegrationId, changeListingSelected, listing } = useContext(
		InvoiceFilterContext
	) as InvoiceFilterContextType;

	const { propertyId } = useAppSelector(({ user }) => user);
	const { allListings } = useAppSelector(({ tra }) => tra);

	useEffect(() => {
		if (propertyId) {
			dispatch(
				getAllListings({
					propertyId,
					appId: APPS.SIRE_TRA || '',
					partnerIntegrationId,
					limit: 500,
					searchInside: [
						PARTNER.STAYS.toString(),
						PARTNER.HOSTFULLY.toString(),
						PARTNER.GUESTY.toString(),
						PARTNER.HOSPITABLE.toString()
					].includes(partnerIntegrationId)
				})
			);
		}
	}, [propertyId]);

	return (
		<div>
			<SelectContainer>
				<Select
					style={{ width: '100%' }}
					size="large"
					bordered={false}
					mode="multiple"
					showSearch
					optionFilterProp="children"
					placeholder="Seleccionar alojamientos"
					filterOption={(input, option) =>
						(option?.label ?? '')
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					filterSort={(optionA, optionB) =>
						(optionA?.label ?? '')
							.toLowerCase()
							.localeCompare((optionB?.label ?? '').toLowerCase())
					}
					options={Array.from(
						new Set(
							allListings.data?.map((e) => {
								if (
									partnerIntegrationId === PARTNER.CLOUDBEDS
								) {
									return e.typeId;
								} else {
									return e._id;
								}
							})
						)
					).map((value) => {
						const item = allListings.data?.find((e) => {
							if (partnerIntegrationId === PARTNER.CLOUDBEDS) {
								return e.typeId === value;
							} else {
								return e._id === value;
							}
						});

						return {
							label: item?.typeName || item?.name,
							value:
								partnerIntegrationId === PARTNER.CLOUDBEDS
									? item?.typeName
									: item?._id
						};
					})}
					allowClear
					maxTagCount={'responsive'}
					onChange={changeListingSelected}
					value={
						allListings.data?.length === 0
							? undefined
							: listing?.filter((e) => e)
					}
					loading={allListings.data?.length === 0}
				/>
			</SelectContainer>
		</div>
	);
};

const SelectContainer = styled.div<any>`
	border: 1px solid ${({ theme }) => theme.colors.gray.base};
	border-radius: 25px;
	width: 240px;
	max-width: 310px;

	${({ disabled }) =>
		disabled &&
		css`
			background-color: #f5f5f5;
		`}

	.ant-select-multiple.ant-select-lg .ant-select-selection-item {
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
		padding-right: 10px;

		& .anticon.anticon-close {
			padding: 4px;
			border: 1px solid #d7d7d7;
			border-radius: 10px;
			width: 20px;
			height: 20px;
			margin-left: 4px;
		}
	}
`;

export default FilterByRoom;
